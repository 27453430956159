const en = {
  translation: {
    lang: 'en',
    languageButtonSwitcher: {
      de: 'English',
      en: 'Deutsch',
    },
    form: {
      addProject: 'Add project',
      applicant: 'Applicant',
      autoSave: {
        title: 'Load Form Data',
        message: 'There is an autosave available. Would you like to load it?',
        accept: 'Yes',
        decline: 'No',
      },
      breathingGas: {
        air: 'Air',
        label: 'Which breathing gas is planned?',
        nitrox: 'Nitrox',
        mixed: 'Mixed',
      },
      clear: 'Clear',
      comment: 'Comment',
      consent: 'Consent by the user',
      consentMsg: 'I hereby consent to the use of my personal data for the use of dive planning.',
      costCentre: 'Cost centre',
      dateFormat: 'MM/DD/YYYY',
      diveMissionLeader: 'Dive mission leader - Name and First name',
      diver: 'Diver - Name and First name',
      diveSiteLocation: 'Dive site/location',
      diveSiteLocationHelp: 'if you plan to visit more than one dive site per mission, please list these sites here',
      email: 'E-mail address project leader',
      error: {
        'consent.required': 'Please confirm that you agree to the use of your personal data',
        'email.required': 'Please enter your e-mail address',
        'email.valid': 'Please enter a valid e-mail address',
        'fullname.required': 'Please enter your name and first name',
        'diveMissionLeader.required': 'Please enter the name and first name of the dive mission leader',
        'diveSiteLocation.required': 'Please enter at least one dive site/location',
        'missionDescription.required': 'Please enter a mission description',
        'numberOfPlanedDives.required': 'Please enter the total number of dives planned during the mission',
        'plannedMissionEnd.min': 'The planned end of the mission must be after the planned start of the mission',
        'plannedMissionStart.min': 'The planned start of the mission must be in the future',
        'plannedUnderwaterWork.required': 'Please enter the underwater work planned during dive mission',
        'projects.required': 'Please enter at least one project',
        'shortDescription.required': 'Please enter a mission ID',
      },
      fullname: 'Name, First name',
      furtherComments: 'Further comments on the mission',
      invoiceAddress: 'Invoice address',
      mailAddress: 'Mail address',
      maximumDiveDepth: 'Maximal dive depth during the mission',
      maximumDiveTime: 'Maximal dive time (min) planned dives during the mission',
      mission: 'Mission',
      missionDescription: 'Mission description',
      numberOfPlanedDives: 'Total number of dives planned during the mission',
      plannedDecompressionDives: 'Is surface supplied diving planned during the mission?',
      plannedMissionEnd: 'Planned end of the mission:',
      plannedMissionStart: 'Planned start of the mission:',
      plannedUnderwaterWork: 'Underwater work planned during dive mission',
      plannedUnderwaterWorkHelp: 'please list the different tasks you are planning to do under water for all different dive sites you plan to visit',
      projects: 'Projects',
      projectTable: 'Project table',
      removeProject: 'Remove project',
      shortDescription: 'Mission ID',
      shortName: 'Short name',
      submit: 'Submit',
      success: 'Your application has been successfully submitted. You will receive a confirmation e-mail shortly.',
      title: 'Dive mission application',
    },
    mission: {
      applicant: 'Applicant / Project leader',
      approved: {
        by: 'Approved by',
        entry: 'Filed on',
        label: 'Mission approved',
        on: 'Approved on',
      },
      divers: 'Divers',
      files: 'Files for the mission',
      furtherComments: 'Further comments on the mission',
      insurance: 'Insurance',
      lastUpdate: 'Last update',
      link: 'Missionlink',
      plannedUnderwaterWork: 'Underwater work planned during dive mission',
      riskAssessment: {
        approved: 'Confirmed',
        by: 'Created by',
        label: 'Risk assessment',
        submitted: 'Submitted',
      },
      specialDefinitions: 'Special definitions for the mission by the AWI Tauchzentrum',
      title: 'Mission',
    },
    projectTitle: 'AWI mission application',
    projectIntro: `
      <h2>The six steps of the AWI research diver deployment</h2>
       <ol>
         <li>
           <p>The planned diving assignment is requested in writing in good time (for one-day assignments at least 14 days before, for multi-day assignments at least four weeks before the planned date) using the web form below.</p>
           <p>A requested diving assignment may include one or more dives. In the case of several dives within one diving operation (e.g. as part of an expedition), the application and the operational permit based on it apply to all of them.</p>
           <p>As part of the application, at least three certified research divers must be named by the applicant for each diving assignment. The applicant proposes a research diver who is technically suitable and experienced in his or her opinion as the leader of the applied diving assignment.</p>
           <p>A professionally qualified dive supervisor is an experienced research diver who can demonstrate at least 100 dives with a minimum diving time of 60 diving hours under operating conditions. The Alfred-Wegener-Institute prefers KFT diving supervisors certified by the Commission for Research Diving Germany (excerpt from GUV-R 21122 - deployment of research divers).</p>
           <p>If no head of operations according to GUV rule 2112 is available for the planned operation, the rule can be deviated from if the head of operations proposed by the applicant can demonstrate sufficient diving experience of his own from similar previous diving operations and that in the GUV-R 2112 - operation can conscientiously fulfill the tasks of a mission leader formulated by research divers, Section 5 in relation to the management of a diving mission.</p>
         </li>
         <li>
           <p>The head of operations proposed by the applicant will be confirmed in writing by the AWI.</p>
         </li>
         <li>
           <p>The confirmed head of operations creates a hazard analysis in accordance with the guidelines specified by the AWI for diving operations (see AWI hazard analysis for diving operations).</p>
         </li>
         <li>
           <p>The diving assignment requested by the applicant will be approved in writing by the AWI either as requested or with changes/conditions.</p>
         </li>
         <li>
           <p>The dive operation is in progress.</p>
         </li>
         <li>
           <p>The diving operations manager submits the fully completed and signed diving protocol (see AWI diving protocol) to the AWI as soon as possible (Scientific Diving Department, AWI-Biologische Anstalt Helgoland, 27498 Heligoland).</p>
         </li>
       </ol>
    `,
    general: {
      no: 'No',
      none: 'None',
      print: 'Print',
      yes: 'Yes',
    },
    imprint: `
      <h3>Imprint:</h3>
      <p><strong>Provider:</strong><br>
      In the legal sense, the provider of this website is the Alfred Wegener Institute for Polar and Marine Research Foundation (AWI).
      </p>
      <p><strong>Address:</strong><br>
      Alfred Wegener Institut<br>
      Kurpromenade<br>
      D-27498 Helgoland<br>
      (Building A-107)
      </p>
      <p><strong>Legal form:</strong><br>
      The Alfred Wegener Institute Foundation is a foundation under public law.<br>
      The AWI is a member of the Helmholtz Association of German Research Centers.
      </p>
      <p><strong>Representative:</strong><br>
      The Alfred Wegener Institute (AWI) Foundation is legally represented by:<br>
      Prof. Dr. Karin Lochte (Director) and Dr. Heike Wolke (Administrative Director).
      </p>
      <p><strong>Sales tax:</strong><br>
      Sales tax identification number according to § 27a sales tax law: DE 114707273
      </p>
    `
  }
}

export default en;

