const de = {
  translation: {
    lang: 'de',
    languageButtonSwitcher: {
      de: 'English',
      en: 'Deutsch',
    },
    form: {
      addProject: 'Projekt hinzufügen',
      applicant: 'Antragsteller',
      autoSave: {
        title: 'Formulardaten laden',
        message: 'Es sind automatisch gespeicherte Formulardaten vorhanden. Möchten Sie diese Daten laden?',
        accept: 'Ja',
        decline: 'Nein',
      },
      breathingGas: {
        air: 'Pressluft',
        label: 'Welches Atemgas ist für den Einsatz geplant?',
        nitrox: 'Nitrox',
        mixed: 'Mischgas',
      },
      clear: 'Löschen',
      comment: 'Kommentar',
      consent: 'Zustimmung durch den Nutzer:',
      consentMsg: 'Hiermit stimme ich der Verwendung meiner personenbezogenen Daten zur Nutzung der Taucheinsatzplanung zu.',
      costCentre: 'AWI Kostenstelle',
      dateFormat: 'DD.MM.YYYY',
      diveMissionLeader: 'Taucheinsatzleiter - Name und Vorname:',
      diver: 'Einsatztaucher - Name und Vorname:',
      diveSiteLocation: 'Tauchgebiet / Tauchstelle',
      diveSiteLocationHelp: 'wenn Sie planen, mehr als einen Tauchplatz pro Mission zu besuchen, listen Sie diese Plätze bitte hier auf',
      email: 'E-Mail Antragsteller',
      error: {
        'consent.required': 'Bitte stimmen Sie der Verwendung Ihrer personenbezogenen Daten zu',
        'email.required': 'Bitte geben Sie ihre E-Mail Adresse an',
        'email.valid': 'Bitte geben Sie eine gültige E-Mail Adresse an',
        'fullname.required': 'Bitte geben Sie Ihren Namen und Vornamen an',
        'diveMissionLeader.required': 'Bitte geben Sie den Namen und Vornamen des Taucheinsatzleiters an',
        'diveSiteLocation.required': 'Bitte geben Sie mindestens einen Tauchplatz an',
        'missionDescription.required': 'Bitte geben Sie eine kurze Beschreibung des Einsatzes an',
        'numberOfPlanedDives.required': 'Bitte geben Sie die Anzahl der geplanten Tauchgänge an',
        'plannedMissionEnd.min': 'Das geplante Ende des Einsatzes muss nach dem geplanten Beginn liegen',
        'plannedMissionStart.min': 'Der geplante Beginn des Einsatzes muss in der Zukunft liegen',
        'plannedUnderwaterWork.required': 'Bitte listen Sie die verschiedenen Aufgaben auf, die Sie unter Wasser für alle verschiedenen Tauchplätze, die Sie besuchen möchten, durchführen möchten',
        'projects.required': 'Bitte machen Sie die Angaben zu mindestens einem Projekt',
        'shortDescription.required': 'Bitte geben Sie eine kurze Beschreibung des Einsatzes an',
      },
      fullname: 'Name, Vorname',
      furtherComments: 'Weitere Bemerkungen zum Einsatz:',
      invoiceAddress: 'Rechnungsadresse',
      mailAddress: 'Mailadresse',
      maximumDiveDepth: 'Maximale Einsatztiefe (m) während des Einsatzes:',
      maximumDiveTime: 'Maximale Dauer eines Tauchganges (min) während des Einsatzes:',
      mission: 'Einsatz',
      missionDescription: 'Einsatzbeschreibung',
      numberOfPlanedDives: 'Anzahl der während des Taucheinsatzes insgesamt geplanten Tauchgänge:',
      plannedDecompressionDives: 'Sind dekompressionspflichige Tauchgänge im Rahmen des Einsatzes geplant?',
      plannedMissionEnd: 'Geplantes Ende des Einsatzes:',
      plannedMissionStart: 'Geplanter Beginn des Einsatzes:',
      plannedUnderwaterWork: 'Auszuführende Aufgaben unter Wasser:',
      plannedUnderwaterWorkHelp: 'Bitte listen Sie die verschiedenen Aufgaben auf, die Sie unter Wasser für alle verschiedenen Tauchplätze, die Sie besuchen möchten, durchführen möchten',
      projects: 'Projekte',
      projectTable: 'Projektübersicht',
      removeProject: 'Projekt entfernen',
      shortDescription: 'Kurzbezeichnung',
      shortName: 'Kurzname',
      submit: 'Abschicken',
      success: 'Ihr Antrag wurde erfolgreich abgeschickt. Sie erhalten in Kürze eine Bestätigungsmail an die angegebene E-Mail Adresse.',
      title: 'Antragsformular',
    },
    mission: {
      applicant: 'Antragsteller / Projektleiter',
      approved: {
        by: 'Genehmigt von',
        entry: 'Eingereicht am',
        label: 'Einsatz Genehmigt',
        on: 'Genehmigt am',
      },
      diver: '{{number}}. Einsatztaucher - Name und Vorname:',
      divers: 'Taucher',
      files: 'Dateien zum Einsatz',
      furtherComments: 'Weitere Bemerkungen zum Einsatz',
      insurance: 'Versicherung',
      lastUpdate: 'Letzte Änderung',
      link: 'Einsatzlink',
      plannedUnderwaterWork: 'Auszuführende Aufgaben unter Wasser',
      riskAssessment: {
        approved: 'Bestätigt am',
        by: 'Erstellt von',
        label: 'Gefahrenanalyse',
        submitted: 'Eingereicht am',
      },
      specialDefinitions: 'Besondere Festlegungen zum Einsatz durch das AWI-Tauchzentrum',
      title: 'Antrag',
    },
    projectTitle: 'AWI-Einsatzantrag',
    projectIntro: `
      <h2>Die sechs Schritte des AWI Forschungstaucher-Einsatz</h2>
      <ol>
        <li>
          <p>Der geplante Taucheinsatz wird rechtzeitig (bei eintägigen Einsätzen  wenigstens 14 Tage vor, bei mehrtägigen Einsätzen wenigstens vier  Wochen vor dem geplanten Datum) über das unten stehende Web-Formular schriftlich beantragt.</p>
          <p>Ein beantragter Taucheinsatz kann einen oder mehrere Tauchgänge beinhalten. Bei mehreren Tauchgängen innerhalb eines Taucheinsatzes (z.B. im Rahmen einer Expedition) gilt die Beantragung und die darauf basierende Einsatzgenehmigung für alle.</p>
          <p>Im Rahmen der Beantragung müssen für jeden Taucheinsatz wenigstens drei geprüfte Forschungstaucher vom Antragsteller benannt werden. Der  Antragsteller schlägt dabei einen aus seiner Sicht fachlich geeigneten  und erfahrenen Forschungstaucher als Einsatzleiter für den beantragten Taucheinsatz vor.</p>
          <p>Ein fachlich geeigneter Taucheinsatzleiter ist ein erfahrener Forschungstaucher, der mindestens 100 Tauchgänge mit einer Mindesttauchzeit von 60 Tauchstunden unter Einsatzbedingungen  nachweien kann. Das Alfred-Wegener-Institut präferiert dabei durch die Kommission Forschungstauchen Deutschland zertifizierte KFT-Tauchereinsatzleiter (Auszug aus der GUV-R 21122 - Einsatz von Forschungstauchern).</p>
          <p>Steht für den geplanten Einsatz kein Einsatzleiter gemäß GUV-Regel 2112 zur Verfügung, kann von der Regel abgewichen werden, wenn der vom Antragsteller vorgeschlagene  Einsatzleiter ausreichend eigene Taucherfahrung aus ähnlichen früheren Taucheinsätzen nachweisen kann und die in der GUV-R 2112 - Einsatz von Forschungstauchern, Abschnitt 5 formulierten Aufgaben eines Einsatzleiters in Bezug auf die Leitung eines Taucheinsatzes gewissenhaft erfüllen kann.</p>
        </li>
        <li>
          <p>Der vom Antragsteller vorgeschlagene Einsatzleiter wird vom AWI schriftlich bestätigt.</p>
        </li>
        <li>
          <p>Der bestätigte Einsatzleiter erstellt eine Gefahrenanalyse gemäß der am AWI für Taucheinsätze vorgegebenen Richtlinien (siehe AWI-Gefahrenanalyse für Taucheinsäze).</p>
        </li>
        <li>
          <p>Der vom Antragsteller beantragte Taucheinsatz wird entweder wie beantragt oder mit Änderungen / Auflagen vom AWI schriftlich genehmigt.</p>
        </li>
        <li>
          <p>Der Taucheinsatz wird durchgeführt.</p>
        </li>
        <li>
          <p>Der Taucheinsatzleiter reicht das vollständig ausgefüllte und vom ihm unterzeichnete Tauchprotokoll (siehe AWI-Tauchprotokoll) baldmöglichst beim AWI ein (Abt. Wissenschaftliches Tauchen, AWI-Biologische Anstalt Helgoland, 27498 Helgoland).</p>
        </li>
      </ol>
    `,
    general: {
      no: 'Nein',
      none: 'Keine',
      print: 'Drucken',
      yes: 'Ja',
    },
    imprint: `
      <h3>Impressum:</h3>
      <p><strong>Anbieter:</strong><br>
      Anbieter dieser Internetpräsenz ist im Rechtssinne die Stiftung Alfred-Wegener-Institut für Polar- und Meeresforschung (AWI).
      </p>
      <p><strong>Adresse:</strong><br>
      Alfred Wegener Institut<br>
      Kurpromenade<br>
      D-27498 Helgoland<br>
      (Building A-107)
      </p>
      <p><strong>Rechtsform:</strong><br>
      Die Stiftung Alfred-Wegener-Institut ist eine Stiftung des Öffentlichen Rechts.<br>
      Das AWI ist Mitglied der Helmholtz-Gemeinschaft Deutscher Forschungszentren e.V.
      </p>
      <p><strong>Vertreter:</strong><br>
      Die Stiftung Alfred-Wegener-Institut (AWI) wird gesetzlich vertreten durch:<br>
      Prof. Dr. Karin Lochte (Direktorin) und Dr. Heike Wolke (Verwaltungsdirektorin).
      </p>
      <p><strong>Umsatzsteuer:</strong><br>
      Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE 114707273
      </p>
    `,
  }
}

export default de;
